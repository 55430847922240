import Links from 'next/link'
import { Fragment } from 'react'
import styled from 'styled-components'

import { theme } from '@/styles/theme'
import { FOOTER_BOTTOM_NAVIGATIONS } from '@/utils/routes'

import { Line } from '@/components/elements/Line'

export const FooterBottom = () => {
  return (
    <Footer>
      <FooterInner>
        <FooterLinks>
          {FOOTER_BOTTOM_NAVIGATIONS.map(({ path, name }) => (
            <Fragment key={`${name}`}>
              <LinkLine color="white" weight={1} direction="vertical" />
              <Links href={path} passHref>
                <StyledText>{name}</StyledText>
              </Links>
            </Fragment>
          ))}
        </FooterLinks>
        <FooterCopyright>
          <p>Copyright © 2022 Trial Holdings Inc. all rights reserved.</p>
        </FooterCopyright>
      </FooterInner>
    </Footer>
  )
}

const Footer = styled.div`
  background-color: ${theme.colors.trialBlue};
  padding: 38px 0 40px;

  @media screen and (max-width: 1047px) {
    padding: 41px 0 39px;
  }
`

const FooterInner = styled.div`
  max-width: 1047px;
  margin: 0 auto;

  @media screen and (max-width: 1047px) {
    max-width: 100%;
    padding: 0 20px;
  }
`

const FooterLinks = styled.nav`
  display: flex;
  justify-content: center;
  letter-spacing: 0.0014em;

  @media screen and (max-width: 1047px) {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 13px 15px;
    letter-spacing: 0.00056em;
  }
`

const LinkLine = styled(Line)`
  opacity: 40%;
  &:first-child {
    display: none;
  }

  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const StyledText = styled.a`
  color: white;
  line-height: 1;
  font-size: 14px;
  transition: all 0.25s ease;

  &:hover {
    text-decoration: underline;
  }

  & + ${LinkLine} {
    margin: 0 1em;
  }
`

const FooterCopyright = styled.div`
  margin-top: 15px;
  text-align: center;

  p {
    color: #ffffff;
    line-height: 2.3em;
    font-size: 14px;
  }

  @media screen and (max-width: 1047px) {
    margin-top: 32px;

    p {
      font-size: 12px;
      line-height: 2.1em;
    }
  }
`
